import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Layout from '../components/layout-default'
import teamsStyles from "../components/teams.module.scss"
import Nav from "../components/nav"
// import rfLogo from "../images/rf-logo-light.svg"
import HeroVideo from "../components/video"
// import Button from "../components/button"

import BachataBegInt from "../images/bachata-beg-int.jpg"
import SalsaBegInt from "../images/salsa-beg-int.jpg"
import CumbiaBegInt from "../images/cumbia-beg-int.jpg"
import RfPlacememts from "../images/RFpalcemts-teams.jpg"
// import SalsaIntAdv from "../images/salsa-int-adv.jpg"
// import BachataIntAdv from "../images/bachata-int-adv.jpg"
import defaultImage from "../images/teams-default.png"
import GroupPhoto from "../images/group-photo.jpg"

import homeStyles from "../components/home.module.scss"
import TeamImg from "../images/team.jpeg"
import promo from "../../src/static/placement-promo2.mp4"
import "../styles/layout.scss"
import "../styles/teams.scss"
import Breadcrumbs from "../components/breadcrumbs";
import MindBodyWidget from "../components/mindbody";
import ErrorBoundary from "../components/ErrorBoundary";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Teams",
  },
];

const Modal = ({ setActiveModal }) => {
  return (
    <div className={`${teamsStyles.modal} modal`}>
      <div className="modal-background" onClick={() => setActiveModal(null)} />
      <div className={`${teamsStyles.modalCard} modal-card`}>
        <div className={`${teamsStyles.modalBody} modal-card-body`}>
          <iframe className={teamsStyles.modalIframe} src="https://slicktext.com/widget/v2/19d913280217b4302a3351a81e056bf0" title="RF Placement Classes Notifications"></iframe>
        </div>
      </div>
      <button onClick={() => { setActiveModal(null) }} className="modal-close is-large" arialabel="close"></button>
    </div>
  )
}

// const Placement = ({ date, link, title }) => {
//   return (
//     <div className={teamsStyles.placement}>
//       <p>{date}</p>
//       <p>{title}</p>
//       <Button bulmaStyle="is-small" styling="isLink" type="a" text="Sign up" href={link}/>
//     </div>
//   )
// }

const Team = ({ src, alt, link, title, desc, linkText, titleTop, titleBottom, children }) => (
  <>
    <div className={`${teamsStyles.column} teams_pages display--inline-block column is-half-desktop is-half-tablet is-three-quarters-mobile`}>
      <div className={teamsStyles.teamDisplay}>
        <Link to={link}>
          <div className={teamsStyles.image}>
            <img src={src ? src : defaultImage} alt={alt} style={{
              height: '402px',
              width: '741px'
            }} />
            <div className={teamsStyles.title}>
              <h4>
                <span className={teamsStyles.titleTop}>
                  {titleTop}
                </span>
                <span className={teamsStyles.titleBottom}>
                  {titleBottom}
                </span>
              </h4>
              <p className={`${teamsStyles.teamsCta} button is-link is-rounded is-small`}>
                Sign up
              </p>
            </div>
          </div>
        </Link>
      </div>
      {(title ? <h4 style={({ 'marginBottom': '0.5em' })}>{title}</h4> : ``)}
      {children}
    </div>
  </>
)

// function getTwoDigits(value) {
//   const res = value.toLocaleString('en-US', {
//     minimumIntegerDigits: 2,
//     useGrouping: false
//   });
//   return res;
// }

// const CountDown = () => {
//   function getTimeLeft() {
//     const auditionDate = new Date('2022-04-28T22:30:00');
//     const today = new Date();
//     const milliseconds = auditionDate.getTime() - today.getTime();
//     const seconds = getTwoDigits(60 - parseInt(new Date().getSeconds(), 10));
//     const minutes = getTwoDigits(60 - parseInt(new Date().getMinutes(), 10));
//     const hours = getTwoDigits(24 - parseInt(new Date().getHours(), 10));
//     const days = getTwoDigits(parseInt(milliseconds/86400000, 10));
//     return { seconds, minutes, hours, days };
//   }
//   const { seconds, minutes, hours, days } = getTimeLeft();
//   return (
//     <div className={teamsStyles.counter}>
//       <span className={teamsStyles.counterItem}>
//         <span className={teamsStyles.counterValue}>{days}</span>
//         <span className={teamsStyles.counterLabel}>DAYS</span>
//       </span>
//       <span className={teamsStyles.counterColon}>:</span>
//       <span className={teamsStyles.counterItem}>
//         <span className={teamsStyles.counterValue}>{hours}</span>
//         <span className={teamsStyles.counterLabel}>HOURS</span>
//       </span>
//       <span className={teamsStyles.counterColon}>:</span>
//       <span className={teamsStyles.counterItem}>
//         <span className={teamsStyles.counterValue}>{minutes}</span>
//         <span className={teamsStyles.counterLabel}>MINS</span>
//       </span>
//       <span className={teamsStyles.counterColon}>:</span>
//       <span className={teamsStyles.counterItem}>
//         <span className={teamsStyles.counterValue}>{seconds}</span>
//         <span className={teamsStyles.counterLabel}>SECS</span>
//       </span>
//     </div>
//   )
// }

const TeamsPage = () => {
  const scriptID = "mb-script-01";

  const [timeLeft, setTimeLeft] = useState(null);
  const [activeModal, setActiveModal] = useState(false);

  setInterval(() => {
    const time = new Date('2022-04-28 22:30:00').getTime() - new Date().getTime();
    setTimeLeft(time);
  }, 1000)

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widgets.mindbodyonline.com/javascripts/healcode';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClickTeamAgreement = () =>{
    window.open("https://1drv.ms/w/s!Akim7jefzOmrjRgUb3D9xfsmbuu_ ");
  }

  const handleClickHeplFullLinks = ()=>{
  
  }

  return (
    <>

      <Layout
        title="Join RF Dance: Bachata and Salsa Teams in Orange County"
        description="Preregister for RF Dance's Latin dance teams. Explore Bachata and Salsa, build community, gain confidence, and immerse yourself in Latin music and culture."
        pathname="teams/"
        img={TeamImg}
        className="team_pages"
      >
        <div className={homeStyles.hero}>
          <div className={homeStyles.heroHead}>
            <Nav isHome />
          </div>
          <div className={homeStyles.heroBody}>
            <div className="container">
              <div className="text-center">
                <div className={homeStyles.heroContent}>
                  <h1 className={`title`}>
                    <span className={teamsStyles.heroHeader}>
                      <span className={teamsStyles.titleRed}>RF</span>
                      <span className={teamsStyles.titleWhite}>TEAMS</span>
                    </span>
                    {/* <span className={teamsStyles.heroTitle}>Join us for team placement classes</span> */}
                    {/* <span className={teamsStyles.countdown}>
                        <CountDown timeLeft={timeLeft}/>
                      </span> */}
                  </h1>
                </div>
                <div className={homeStyles.heroContent}>
                  <HeroVideo
                    isTeams
                    promo={promo}
                  // secondaryLinkFunction={() => {
                  //   setActiveModal(true);
                  // }}
                  // secondaryBtnType="button"
                  // secondaryBtnText="Save your spot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className={`${teamsStyles.content}  content thirds teams_page`}>
          
           {/* <div className={`${teamsStyles.contentBlock} content-block`}> */}
            <div className="container">
              <Breadcrumbs crumbs={crumbs} />

              <div className="btn_container">
                <button className="teamBtn_links" onClick={handleClickTeamAgreement}>Team Agreement</button>
                <button className="teamBtn_links"> <a href="/helpfull">Helpful Links</a></button>
                <button className="teamBtn_links"><a href="/performance-and-events">Performances & Events</a> </button>
              </div>

          {/* <div className="container"> */}
          <healcode-widget
              data-type="schedules"
              data-widget-partner="object"
              data-widget-id="d421016309b0" 
              data-widget-version="1"
            ></healcode-widget>
          </div>

          <div className="container">
            <h2 className="title">
              Sign up for RF Dance Team Rehearsals,  Performances & Events
            </h2>

            {/* <healcode-widget
              data-type="schedules"
              data-widget-partner="object"
              data-widget-id="d421016309b0"
              data-widget-version="1"
            ></healcode-widget> */}
          </div>


          <div className={`${teamsStyles.contentBlock} content-block`}>
            <div className="container">
              <div className={`${teamsStyles.columns} columns display--flex-center`}>
                <Team
                  src={BachataBegInt}
                  alt="Bachata Beg/Int"
                  titleTop="Bachata"
                  titleBottom="Teams"
                  link="/teams/bachata/"
                  desc=""
                  linkText="Learn more">
                  <p className={teamsStyles.desc}>Our Fusion-style bachata teams are perfect for anyone looking to express themselves through Latin music and culture. We offer a variety of open levels, from our renowned intro to bachata team to our multi-award-winning Competition team! We have a spot in our community to reach your goals!</p>
                </Team>
                <Team
                  src={SalsaBegInt}
                  alt="Salsa Beg/Int"
                  titleTop="Salsa"
                  link="/teams/salsa/"
                  titleBottom="Teams"
                  desc="The RF Men’s Team features a strong and expressive blend of modern Latin music and choreography."
                >
                  <p className={teamsStyles.desc}>RF Salsa is a modern take on a culture-filled dance. Explore its history and movement through music that will take you from the heart of LA Salsa to New york Mambo On2. We have spots available for people taking their first steps to ON2 connoisseurs.</p>
                </Team>

              </div>
              <div className={`${teamsStyles.columns} columns display--flex-center`} >
                <Team
                  src={CumbiaBegInt}
                  alt="Cumbia Beg/Int"
                  titleTop="Cumbia"
                  link="/teams/cumbia/"
                  titleBottom="Teams"
                  desc=""
                >
                  <p className={teamsStyles.desc}>Dive into the world of Cumbia with RF Dance Teams, welcoming all from beginners to experts. Experience captivating rhythms and learn vibrant routines for a chance to perform and join our inclusive community. It's not just about dancing—it's an opportunity to grow your network and shine. Embrace this exciting challenge; become a proud Rf Cumbia Dance Team member today!</p>
                </Team>
                {/* <Team
                  src={RfPlacememts}
                  alt="RfPlacememts "
                  titleTop="Schedule a Make-up Appointment"
                  link="https://docs.google.com/forms/d/1apBolPjWgGKX9DL8WCOxfXVS-msLywSsYuvmOs2mvhI/edit"
                  titleBottom="for RF Team Placement"
                  desc=""
                >
                  <p className={teamsStyles.desc}>Missed the Placements? It's not too late! Complete our form, and we'll reach out to you!</p>
                </Team> */}
              </div>

            </div>
          </div>
          
          <hr className="divider" />

          <div className="content-block text-center">
            <div className="container">
              <h2 className="title">
                Benefits of Latin Dance Teams
              </h2>
              <div className={`columns is-8 is-variable ${teamsStyles.benefitsColumn}`}>
                <div className="column is-one-third">
                  <img src={GroupPhoto} alt="RF Dance Family" style={{ width: '100%' }} />
                </div>
                <div className={`column is-one-third ${teamsStyles.teamValue}`}>
                  <h3>Build Community</h3>
                  <p>Create a purpose driven company that will help you grow in life through dance.</p>

                  <h3>Be Disciplined</h3>
                  <p>Work on your routine because an execution plan will help you reach your goals.</p>

                  <h3>Gain Confidence</h3>
                  <p>Confidence is built on accomplishment! Set your dance goals and kill it on stage!</p>
                </div>
                <div className={`column is-one-third ${teamsStyles.teamValue}`}>
                  <h3>Learn Culture</h3>
                  <p>Immerse yourself into Latin music & dance its values and rituals.</p>

                  <h3>Join Group activities</h3>
                  <p>Party, play and travel</p>

                  <h3>Improve Social Skills</h3>
                  <p>Connect with dancers of all backgrounds while practicing, dancing and performing together.</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="divider" />
          {/* <div className="content-block text-center">
              <div className="container">
                <div className={homeStyles.heroContent}>
                  <h1 className={`title`}>
                    <span className={teamsStyles.heroTitle}>Save your spot</span>
                    <span className={teamsStyles.countdown}>
                      <CountDown timeLeft={timeLeft}/>
                    </span>
                  </h1>
                  <Button
                    type="button"
                    onClick={() => {setActiveModal(true)}}
                    styling="isLink"
                    text="Save your spot"/>
                </div>
              </div>
            </div> */}
        </section>
      </Layout>
      {activeModal && (
        <Modal activeModal={activeModal} setActiveModal={setActiveModal} />
      )}
    </>
  )
}

export default TeamsPage